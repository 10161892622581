// import { Accordion } from "rsuite";
// const FaQ = () => {
//   const items = [
//     {
//       header: "How much do Customers pay?",
//       content: "Lorem ipsum dolor sit amet, consectetur adipiscing...",
//     },
//     {
//       header: "Will customers actually opt-in and pay it?",
//       content: "Quisque eget luctus mi, vehicula mollis lorem...",
//     },
//     {
//       header: "Is Navidium App an Insurance Company?",
//       content: "Suspendisse massa risus, pretium id interdum in...",
//     },
//     {
//       header: "How are you different from Route Insurance?",
//       content: "Suspendisse massa risus, pretium id interdum in...",
//     },
//     {
//       header: "How does Navidium Make Money?",
//       content: "Suspendisse massa risus, pretium id interdum in...",
//     },
//     {
//       header: "Can you display your widget at checkout?",
//       content: "Suspendisse massa risus, pretium id interdum in...",
//     },
//     {
//       header: "How do we file and Manage Claims?",
//       content: "Suspendisse massa risus, pretium id interdum in...",
//     },
//     {
//       header: "What can I do with Claims?",
//       content: "Suspendisse massa risus, pretium id interdum in...",
//     },
//     {
//       header: "We use Route now, Can you help us remove them and switch?",
//       content: "Suspendisse massa risus, pretium id interdum in...",
//     },
//   ];
//   return (
//     <div className="text-center my-8">
//       <h2 className="text-green-600 font-bold text-2xl md:text-3xl mb-2">
//         Frequently Asked Questions
//       </h2>
//       {/* <div className="min-w-full bg-gray-100 py-6">
//         <h2 className="font-bold text-xl md:text-2xl mb-2">
//           What is the Benefit of Navidium?
//         </h2>
//         <div className="flex justify-center">
//           <p className="w-2/3 text-left ">
//             With the rising cost of shipping those few orders a month that get
//             "lost" have slowly become more and more expensive. Navidium gives
//             you a tool to help off-set that cost by allowing you to charge a
//             small fee from your customers in exchange for a shipping delivery
//             guarantee.
//           </p>
//         </div>
//       </div> */}
//       {/* accordion  */}
//       <div className="w-[95vw] sm:w-[50vw] m-auto">
//         <Accordion>
//           {items.map((item, index) => (
//             <Accordion.Panel key={index} header={item.header}>
//               <p className="text-start">{item.content}</p>
//             </Accordion.Panel>
//           ))}
//         </Accordion>
//       </div>
//     </div>
//   );
// };

// export default FaQ;

import React from 'react';

const FaQ = () => {
  return <></>;
};

export default FaQ;
